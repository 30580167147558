import React from "react";

import { DiscussionEmbed } from "disqus-react";
// import { Disqus } from 'gatsby-plugin-disqus';

import { useSiteMetadata } from "@/hooks";

interface Props {
  postTitle: string;
  postSlug: string;
}

const Comments: React.FC<Props> = ({ postTitle, postSlug }: Props) => {
  const { url, disqusShortname } = useSiteMetadata();

  if (!disqusShortname) {
    return null;
  }

  return (
    <DiscussionEmbed
      shortname={disqusShortname}
      config={{
        url: url + postSlug,
        identifier: postTitle,
        title: postTitle,
      }}
    />
    // <Disqus
    //   config={{
    //     url: url + postSlug,
    //     identifier: postTitle,
    //     title: postTitle,
    //     }}
    // />
  );
};

export default Comments;
